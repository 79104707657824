import { Reducer } from 'redux'
import { AllocateFundState, AllocateFundActionTypes } from './types'
import { AuthActionTypes } from '../auth'

export const initialState: AllocateFundState = {
  isSubmitting: false,
  success: false,
  failure: false,
  error: undefined,
  isFetching: false,
  transactions: []
}

const reducer: Reducer<AllocateFundState> = (state = initialState, action) => {
  switch (action.type) {
    case AuthActionTypes.DESTROY_STATES:
      return initialState
    case AllocateFundActionTypes.ALLOCATE_FUNDS_REQUEST:
      return {
        ...state,
        loading: true,
        success: false,
        error: undefined,
        failure: false
      }
    case AllocateFundActionTypes.ALLOCATE_FUNDS_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true
      }
    case AllocateFundActionTypes.ALLOCATE_FUNDS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
        failure: true
      }
    case AllocateFundActionTypes.FUNDS_TRANSACTIONS_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: undefined,
      }
      case AllocateFundActionTypes.FUNDS_TRANSACTIONS_SUCCESS:
        return {
          ...state,
          isFetching: false,
          transactions: action.payload
        }
      case AllocateFundActionTypes.CLEAR_STATES:
        return {
          ...state,
          isFetching: false,
          success: false,
          failure: false,
          error: undefined
        }
    default:
      return state
  }
}

export { reducer as allocateFundsReducer }
