import React from 'react'
import { Input, Row, Col, Button, Alert, Select, Modal } from 'antd'
import 'antd-country-phone-input/dist/index.css'
import { MerchantData } from '../../interfaces'
import { isEmpty } from '../../helpers/isEmpty';

interface Props {
  onSubmit(values: any) : void
  isSubmitting: boolean
  Form: any
  form: any
  errors: any
  success: boolean
  merchants: MerchantData[]
  translate: any
  showModal?: boolean
  onToggleModal: () => void
}

const FundAllocation: React.FC<Props> = ({
  Form,
  errors,
  form,
  isSubmitting,
  onSubmit,
  success,
  merchants,
  translate,
  showModal,
  onToggleModal
}) => {
    const { Option } = Select;

  return (
    <Modal
    title={translate('general.Allocate_Funds')}
    maskClosable={false}
    centered
    open={showModal}
    onCancel={() => null}
    footer={[
      <Button
        type="default"
        key="cancel"
        onClick={() => onToggleModal()}
        className="new-cancel-btn"
      >
        {translate('general.close')}
      </Button>,
      <Button
        form="allocate-funds-form"
        key="submit"
        htmlType="submit"
        type="primary"
        loading={false}
        className="new-page-btn"
      >
        {translate('general.send')}
      </Button>
    ]}
  >
    <Row justify="center" align="middle">
      <Col span={20} md={20} sm={24} xs={24} className="profile-box">
        <Form
          form={form}
          name="allocate-funds-form"
          onFinish={(onSubmit)}
          scrollToFirstError
        >
          {success ? (
            <div style={{ marginBottom: 20 }}>
              <Alert
                message="Transaction Successful"
                type="success"
              />
            </div>
          ) : null}
          {errors ? (
            <div style={{ marginBottom: 20 }}>
              <Alert message={JSON.stringify(errors)} type="error" />
            </div>
            ) : null}
                                  
            <Form.Item 
                name="merchant"
                hasFeedback
                rules={[{ required: true, message: 'This field is required' }]}
                      >
            <Select
                placeholder={`${translate('general.merchant')}: ${translate(
                    'general.default'
                )}=${translate('general.all')}`}
                style={{ width: '100%' }}
                allowClear
                showSearch
                optionFilterProp="children"
                filterOption={(input: any, option: any) =>
                    option.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
                className="select-merchant"             
            >
                {!isEmpty(merchants) &&
                    merchants.map((m) => (
                    <Option value={m.merchantId} key={m.merchantId}>
                        {m.name}
                    </Option>
                ))}
            </Select>
            </Form.Item>
            <Form.Item name="currency" 
                class="ant-row ant-form-item-row"
                hasFeedback
                rules={[{ required: true, message: 'This field is required' }]}
            >
            <Select
                className='ant-select-selector-text'
                placeholder={`${translate('general.currency')}`}
            >
                <Option value="USD" key="USD">
                    USD
                </Option>
                <Option value="CDF" key="CDF">
                    CDF
                </Option>
            </Select>
        </Form.Item>         
                  
        <Form.Item
            hasFeedback
            name="amount"
            rules={[{ required: true, message: 'This field is required' }]}
        >
            <Input placeholder={translate('general.amount')} />
        </Form.Item>
        </Form>
      </Col>
      </Row>
      </Modal>
  )
}

export default FundAllocation
