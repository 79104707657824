import React from 'react'
import { Row, Col, Table } from 'antd'
import { appSelector } from '../../helpers/appSelector'
import Filters from './Filters'
import moment from 'moment'
import { timeZones } from '../../helpers/constants'
import { FundTransaction as TT } from "../../interfaces";

interface Props {
    onLoadMore(page: any, pageSize: any): void
    translate: any
    total: number
    setOpenModal: () => void
}
  
const AllocateFundsDashboard: React.FC<Props> = (props) => {
    const {  onLoadMore, translate, total, setOpenModal } = props
  const { transactions, isFetching} = appSelector((state) => state.allocateFundState);
  const reports = appSelector((state) => state.reports)
    const { merchants } = reports

    const columns: any = [
      {
        title: <span style={{ fontSize: "2rem", color: "#868686" }}>&bull;</span>,
        dataIndex: "status",
        key: "status",
        align: "center",
        className: "column-text",
        render: (status: string) => {
          return <span style={{ fontSize: "2rem", color: "#868686" }}>&bull;</span>;
        },
      },
      {
        title: `${translate("general.amount")}`,
        dataIndex: "amount",
        key: "amount",
        align: "center",
        className: "column-text",
      },
      {
        title: `${translate("general.debitAccount")}`,
        dataIndex: "debitAccount",
        key: "debitAccount",
        align: "center",
        className: "column-text",
      },
      {
        title: `${translate("general.creditAccount")}`,
        dataIndex: "creditAccount",
        key: "creditAccount",
        align: "center",
        className: "column-text",
      },
      {
        title: `${translate("general.Date")}`,
        dataIndex: "trxDate",
        key: "trxdate",
        align: "center",
        className: "column-text",
        responsive: ["lg"],
        render: (date: string) => {
          // const d = moment(date, 'MM/DD/YYYY HH:mm:ss')
          const d = moment(date)
            .tz(timeZones.kinshasa)
            .format(`MMMM D, YYYY (h:mm a)`);
          return <span>{d}</span>;
        },
      },
      {
        title: `${translate("general.status")}`,
        dataIndex: "status",
        key: "status",
        align: "center",
        className: "column-text",
        responsive: ["lg"]
      }
  ];

  let dataSource: TT[] = [];
  for (let transaction of transactions) {
    dataSource.push({
      amount: `${transaction.currency} ${transaction.amount}`,
      transactionId: transaction.transactionId,
      trxDate: transaction.trxDate,
      status: transaction.status,
      debitAccount: transaction.debitAccount,
      creditAccount: transaction.creditAccount,
      currency: transaction.status,
    });
  }

  return (
    <Row gutter={20}>
      <Col span={24}>
        <div className="table-padding ">
        <Filters
          merchants={merchants}
          onSearch={() => null}
          onReset={() => null}
          translate={translate}
          setOpenModal={setOpenModal}
          />
        </div>
        <div className="table-padding">
          <Table
            dataSource={dataSource}
            columns={columns}
            bordered
            className="tranaction-table"
            onRow={(t: TT) => ({
              onClick: () => null,
            })}
            pagination={{
              onChange: (page, pageSize) => {
                onLoadMore(page, pageSize)
              },
              total: total,
              showTotal: (_, range) => {
                const tran = translate(`general.pagination`)
                let t = tran.replace(`%d`, `${range[0]} - ${range[1]}`)
                let s = t.replace(`%s`, total)
                return s
              },
              showSizeChanger: false
            }}
            loading={isFetching}
            />
          </div>
      </Col>
    </Row>
  )
}

export default AllocateFundsDashboard
