import { action } from 'typesafe-actions'
import { AllocateFundActionTypes } from './types'

export const allocateFundsRequest = (payload: any) => action(AllocateFundActionTypes.ALLOCATE_FUNDS_REQUEST, payload)
export const allocateFundSuccess = (response: any) =>
  action(AllocateFundActionTypes.ALLOCATE_FUNDS_SUCCESS, response)
export const allocateFundFailure = (error: any) => action(AllocateFundActionTypes.ALLOCATE_FUNDS_FAILURE, error)
export const fundTransactionsRequest = (payload: any) => action(AllocateFundActionTypes.FUNDS_TRANSACTIONS_REQUEST, payload)
export const fundTransactionsSuccess = (response: any) =>
  action(AllocateFundActionTypes.FUNDS_TRANSACTIONS_SUCCESS, response)
export const fundTransactionsFailure = (error: any) => action(AllocateFundActionTypes.FUNDS_TRANSACTIONS_FAILURE, error)
export const clearStates = () => action(AllocateFundActionTypes.CLEAR_STATES)
